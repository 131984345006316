import React from 'react';
import svg from '../resources/svg.png';
import kam from '../resources/kam.png';
import sst from '../resources/sst.png';
import nla from '../resources/nla.png';
import pat from '../resources/pat.png';
import mlo from '../resources/mlo.png';
import dhe from '../resources/dhe.png';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    leftImage: {
        order: 0,  // Stellt sicher, dass das Bild links ist
        marginLeft: '24px',
        marginRight: '24px',
    },
    rightImage: {
        order: 1,  // Stellt sicher, dass das Bild rechts ist
        marginLeft: '24px',
        marginRight: '24px',
    },
    quoteContainer: {
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 1000px)': {
            flexDirection: 'column',
            alignItems: 'center',
        }     
    },
    quoteImage: {
        width: '200px',
        marginRight: '20px',
        '@media (max-width: 1000px)': { //make image smaller on small screens
            width: '32%',
            marginBottom: '8px',  // Platz zwischen Bild und Text
            marginRight: '8px',
            marginLeft: '8px',
            order: 0,  // makes sure picture is on upper side, when small screen
        }
    },
    text: {
        '@media (max-width: 1000px)': {
            order: 1,  // Stellt sicher, dass der Text unten ist
            textAlign: 'center',  // Optional: Zentriert den Text
        }
    },
    quoteContainerMargin: {
        marginBottom: '40px',
    },
    textContainer: { 
        '@media (max-width: 1000px)': {
            clear: 'both',
        }
    }
});




const PHATteStimmenComponent: React.FC = () => {
    const classes = useStyles();

    return (
        <>
            <div className={`${classes.quoteContainer} ${classes.quoteContainerMargin}`}>
                <img src={kam} alt="Katharina Mühe" className={`${classes.quoteImage} ${classes.leftImage}`} />
                <div className={classes.text}>
                    "Wenn ich an PHAT denke, kommen mir zwei Begriffe in den Kopf: Eigenverantwortlichkeit und Offenheit. Eigenverantwortlichkeit: Du bestimmst maßgeblich, in welche Bereiche und Projekte du deine Zeit investieren möchtest – und das ist keine Floskel, sondern gelebte Kultur. Offenheit: Du kannst überall mitwirken und wirst immer herzlich empfangen. Sowohl im Arbeitskontext – als auch im Privaten."<br />
                    <b>- Katharina Mühe</b>
                </div>
            </div>

            <div className={`${classes.quoteContainer} ${classes.quoteContainerMargin}`}>
                <div className={classes.text}>
                        "PHAT CONSULTING ist nicht nur ein Arbeitsplatz mit Top-Consultants, sondern auch ein Ort zum Wohlfühlen, Feiern und Austauschen. Und ein Ort, um gemeinsam PHATtes zu leisten, Kunden langfristig bei der Weiterentwicklung zu unterstützen und zu Fans zu machen – selbstorganisiert und auf Augenhöhe."
                    <br />
                    <b>- Nils Langemann</b>
                </div>
                <img src={nla} alt="Nils Langemann" className={`${classes.quoteImage} ${classes.rightImage}`} />
            </div>

            <div className={`${classes.quoteContainer} ${classes.quoteContainerMargin}`}>
                <img src={dhe} alt="Daniel Henschel" className={`${classes.quoteImage} ${classes.leftImage}`} />
                <div className={classes.text}>
                        "Das Beste an uns: Wir sind keine Firma. PHAT sieht sich als kulturelles Fundament, welches aus eigenverantwortlichen Menschen besteht. Gemeinsam unterstützen wir Organisationen auf ihrem Weg in die digitale Arbeitswelt."
                    <br />
                    <b>- Daniel Henschel</b>
                </div>
            </div>

            <div className={`${classes.quoteContainer} ${classes.quoteContainerMargin}`}>
            <div className={classes.text}>
                    "PHAT CONSULTING ist ein Schnellboot und bei allen Dienstleistungen nah an den eigentlichen Herausforderungen und vor allem: nah am Menschen. Keine Buzzwords. Stattdessen: Pragmatische Lösungen für eine zukunftsfähige Wertschöpfung mit weniger Schadschöpfung. Und das immer als Teamsport. Wenn das kein Grund ist, hier zu arbeiten!"<br />
                    <b>- Sven Grönwoldt</b>
                </div>
                <img src={svg} alt="Sven Grönwoldt<" className={`${classes.quoteImage} ${classes.rightImage}`} />
            </div>

            <div className={`${classes.quoteContainer} ${classes.quoteContainerMargin}`}>
                <img src={sst} alt="Sören Stein" className={`${classes.quoteImage} ${classes.leftImage}`} />
                <div className={classes.text}>
                    "Was Daniel sagt. ;-)"<br />
                    <b>- Sören Stein</b>
                </div>
            </div>

            <div className={`${classes.quoteContainer} ${classes.quoteContainerMargin}`}>
                <div className={classes.text}>
                    "PHAT verkörpert eine vielfältige Gemeinschaft aus Menschen mit unterschiedlichem Hintergrund, die gemeinsam nach Veränderungen streben. Für mich steht PHAT für die Entfaltung aufregender neuer Möglichkeiten durch Eigenorganisation, Teamwork und gegenseitiges Vertrauen."<br />
                    <b>- Maike Löwenkamp</b>
                </div>
                <img src={mlo} alt="Maike Löwenkamp" className={`${classes.quoteImage} ${classes.rightImage}`} />
            </div>

            <div className={classes.quoteContainer}>
                <img src={pat} alt="Patrick Timmermann" className={`${classes.quoteImage} ${classes.leftImage}`} />
                <div className={classes.text}>
                    "PHAT ist anders, PHAT ist besser: hier triffst Du auf echten Teamgeist, arbeitest immer auf Augenhöhe, bekommst Raum für Deine eigenen Ideen und genießt ein hohes Maß an Freiheit, alles gepaart mit einem ordentlichen Schuss Eigenverantwortung. Wer das erleben möchte und wer Lust hat, mit tollen Menschen an seiner Seite, Unternehmen in der Gestaltung ihrer digitalen Arbeitswelten ganz nach vorne zu bringen, der muss einfach zu PHAT kommen!"<br />
                    <b>- Patrick Timmermann</b>
                </div>
            </div>
        </>
    );
}

export default PHATteStimmenComponent;
