import { useState } from 'react';
import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';

import theme from '../theme/theme';
import { IconProps } from '../resources/Footer_Icons/Footer_Icons';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    marginTop: 0,
    fontFamily: 'dinregular,Helvetica, Arial, sans-serif',
    color: '#fff',
  },
  IconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginRight: 10,
    width: '20px',
    height: '20px',
    color: theme.palette.secondary.main,
  },
  icon: {
    //height: '80%',
  },
});

const WrappedIcon = (props: {
  icon: string | null | ((props: IconProps) => JSX.Element);
  iconColor: string;
}): JSX.Element => {
  const classes = useStyles();

  if (props.icon === null) {
    return (
      <div className={classes.IconWrapper} style={{ opacity: 0 }}>
        <div className={classes.icon} />
      </div>
    );
  }

  if (typeof props.icon == 'string') {
    return (
      <div className={classes.IconWrapper}>
        <img src={props.icon} style={{ width: 20, height: 20, fill: '#fff' }} />
      </div>
    );
  }

  return (
    <div className={classes.IconWrapper}>
      <props.icon
        fillColor={props.iconColor}
        style={{ width: 20, height: 20 }}
      />
    </div>
  );
};

const LinkIconWrapper = (props: {
  icon: string | null | ((props: IconProps) => JSX.Element);
  linkText: string;
  linkURL: string;
  color?: string;
  colorSecondary?: string;
}): JSX.Element => {
  const classes = useStyles();

  const [isHovering, setHovering] = useState(false);

  return (
    <>
      <Link
        className={classes.link}
        href={props.linkURL}
        style={{
          color: isHovering
            ? (props.colorSecondary ?? theme.palette.secondary.light)
            : (props.color ?? '#fff'),
        }}
        target="_blank"
        onMouseEnter={() => {
          setHovering(true);
        }}
        onMouseLeave={() => {
          setHovering(false);
        }}
      >
        <WrappedIcon
          icon={props.icon}
          iconColor={
            isHovering
            ? (props.colorSecondary ?? theme.palette.secondary.light)
            : (props.color ?? '#fff')
          }
        />
        {props.linkText}
      </Link>
    </>
  );
};

export default LinkIconWrapper;
