import { makeStyles } from '@mui/styles';
import { Container, Typography } from '@mui/material';
import ApplicationConfirmationImage from '../resources/applicationConformationImage.jpg';
import LinkIconWrapper from '../components/LinkIconWrapper';
import { Instagram_Icon, Kununu_Icon, LinkedIn_Icon } from '../resources/Footer_Icons/Footer_Icons';
import theme from '../theme/theme';

const useStyles = makeStyles({
  font: {
    marginBottom: 0,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    fontFamily: 'dinregular,Helvetica, Arial, sans-serif',
  },
  headline: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    fontFamily: 'Graviton',
  },
  block: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '20px',
  },
});

const ApplicationConfirmationPage = (): JSX.Element => {
  const classes = useStyles();

  return (

    <Container maxWidth="md" style={{ backgroundColor: '#fff', textAlign: 'justify'}} >
      <div className={classes.block}>
        <Typography className={classes.headline} variant="h4">
          Dein Weg zu uns – So läuft’s ab!
        </Typography>
        </div>
        <div className={classes.block}>
        <Typography className={classes.font} variant="h6" gutterBottom>Schritt 1: Bewerbung abgeschickt – und jetzt?</Typography>
        <Typography className={classes.font}>
          Glückwunsch, du hast den ersten Schritt gemacht! Deine Bewerbung ist bei uns eingegangen, und du bekommst eine Eingangsbestätigung per E-Mail.
        </Typography>
        </div>
        <div className={classes.block}>
        <Typography className={classes.font} variant="h6" gutterBottom>Schritt 2: Wir schauen uns das an</Typography>
        <Typography className={classes.font}>
          Unser Team nimmt sich Zeit, deine Unterlagen genau anzusehen. Wir melden uns schnellstmöglich bei dir – versprochen!
        </Typography>
        </div>
        <div className={classes.block}>
        <Typography className={classes.font} variant="h6" gutterBottom>Schritt 3: Erstes Kennenlernen</Typography>
        <Typography className={classes.font}>
          Wenn’s passt, laden wir dich zu einem lockeren Austausch ein – entweder remote oder vor Ort.
        </Typography>
        </div>
        <div className={classes.block}>
        <Typography className={classes.font} variant="h6" gutterBottom>Bleib auf dem Laufenden!</Typography>
        <Typography className={classes.font}>
          Du willst mehr über das PHATte Universum erfahren?
        </Typography></div>
        <div style={{ display: 'flex', alignItems: 'center', padding: '20px', gap: 20}}>
          <LinkIconWrapper
              color={theme.palette.secondary.light}
              colorSecondary={theme.palette.secondary.main}
              icon={LinkedIn_Icon}
              linkText="LinkedIn"
              linkURL="https://de.linkedin.com/company/phat-consulting-gmbh"
            />
            <LinkIconWrapper
              color={theme.palette.secondary.light}
              colorSecondary={theme.palette.secondary.main}
              icon={Instagram_Icon}
              linkText="Instagram"
              linkURL="https://www.instagram.com/phatconsulting/?hl=de"
            />

            <LinkIconWrapper 
              color={theme.palette.secondary.light}
              colorSecondary={theme.palette.secondary.main}
              icon={Kununu_Icon}
              linkText="Kununu"
              linkURL="https://www.kununu.com/de/phat-consulting2"
            />
          </div>
      <img
        alt="Foto von glücklichen PHATties"
        src={ApplicationConfirmationImage}
        style={{padding: '20px'}}
        width="100%"
        />
    </Container>
  );
};

export default ApplicationConfirmationPage;
